import {useEffect, useState} from 'react';
import List from '@mui/material/List';
import {NavItem} from './NavItem';
import {SpartanMenuItem} from '../model/SpartanMenuItems';
import {FeatureName} from '../../paths';
import UserRoleService from '../services/UserRoleService';
import {NextgenFormSchema} from '../form/api/middleware/NextgenFormSchema';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {useAxiosContext} from '../context/AxiosContext';
import {useLoading} from '../context/LoadingContext';
import CollapsableNavItem from './CollapsableNavItem';
import SideNavBalance from './SideNavBalance';
import UserService from '../services/UserService';

interface SideNavProps {
  isSideNavOpen: boolean;
}
function SideNav({isSideNavOpen}: SideNavProps) {
  const {useAxiosFormAPI} = useAxiosContext();
  const [sideNavItems, setSideNavItems] = useState<Array<SpartanMenuItem>>([]);
  const [userAccountIds, setUserAccountIds] = useState<Array<string>>([]);
  const {setLoading} = useLoading();
  const [{response: getMenuItemsResponse, error: getMenuItemsError, loading: itemsLoading}, getMenuItems] =
    useAxiosFormAPI<NextgenFormSchema>(
      {
        url: `/featurefields`,
        method: 'GET',
        params: {feature_name: FeatureName.SIDENAV},
      },
      {manual: true}
    );

  useEffect(() => {
    if (getMenuItemsResponse) {
      setSideNavItems(getMenuItemsResponse?.data?.menu_items || []);
    }
  }, [getMenuItemsResponse]);

  useEffect(() => {
    setLoading(itemsLoading, 'SideNav');
  }, [itemsLoading]);

  useEffect(() => {
    if (getMenuItemsError) {
      NotificationService.getInstance().sendNotification(
        getMenuItemsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getMenuItemsError]);

  useEffect(() => {
    getMenuItems();
  }, [UserRoleService.getInstance().getCurrentValue()]);
  useEffect(() => {
    const subscription = UserService.getInstance()
      .get()
      .subscribe((user) => {
        if (user?.account_ids) {
          setUserAccountIds(user.account_ids);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <List
      sx={{
        padding: userAccountIds.length > 0 ? '0 8px 8px' : '8px 8px 8px',
        overflow: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#dbe6fa white',
      }}
    >
      <SideNavBalance />
      {sideNavItems?.map((item: SpartanMenuItem, index: number) => {
        if (item.children) {
          return (
            <CollapsableNavItem
              isSideNavOpen={isSideNavOpen}
              item_id={item.menu_item_id}
              key={index}
              title={item.label}
              tooltip={item.tooltip}
              icon={item.icon}
              children={item.children}
            />
          );
        } else {
          return (
            <NavItem
              href={item.action}
              key={item.menu_item_id}
              title={item.label}
              icon={item.icon}
              isCollapse={false}
              tooltip={item.tooltip}
              hasChildren={false}
            />
          );
        }
      })}
    </List>
  );
}

export default SideNav;
