import {Box, Card, CardHeader, Stack, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface ServiceCardProps {
  service: string;
  amount: number;
  minutes: number;
  key: string;
}

export default function ServiceCard({service, amount, minutes}: ServiceCardProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Card sx={{display: 'inline-block', m: '0 24px', width: '200px'}}>
      <CardHeader
        title={t(`billing.${service}`.replaceAll('_', '-'))}
        sx={{width: '100%', backgroundColor: theme.palette.primary.main}}
        titleTypographyProps={{variant: 'h3'}}
      />
      <Box sx={{p: 2}}>
        <Stack direction={'row'} justifyContent="space-between">
          <Stack direction={'column'}>
            <Typography variant="h5" component="div" mb={0.5}>
              {t('billing.spent')}
            </Typography>
            <Typography variant="h5" component="div" fontSize={'1.3rem'} fontWeight={400} color="text.secondary">
              ${Math.round(amount * 100) / 100}
            </Typography>
          </Stack>
          <Stack direction={'column'}>
            <Typography variant="h5" component="div" mb={0.5}>
              {service === 'rvm' ? t('billing.rvms') : t('billing.minutes')}
            </Typography>
            <Typography variant="h5" component="div" color="text.secondary" fontSize={'1.3rem'} fontWeight={400}>
              {Math.round(minutes * 100) / 100}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
}
